import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  message,
  Modal,
  Col,
  Row,
  Typography,
  Divider,
  Radio,
  InputNumber,
  Switch,
  Segmented,
  Input,
} from "antd";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Timer from "../assets/SideBarImages/Timer.png";
import Dashboard from "../assets/SideBarImages/DashboardBlack.png";
import Pause from "../assets/SideBarImages/Pause.png";
import PointOfSale from "../assets/SideBarImages/PointOfSale.png";
import Rights from "../assets/SideBarImages/Rights.png";
import ReactToPrint from "react-to-print";
import Bill from "../assets/Bill";
import Kot from "../assets/Kot";
import { useNavigate } from "react-router-dom";
import Title from "antd/es/skeleton/Title";
import Food from "../assets/SideBarImages/foodplaceholder.png";

const { Text } = Typography;

const OngoingOrders = () => {
  const [tables, setTables] = useState([]);
  const [ongoingTableItems, setOngoingTableItems] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [orderAmount, setOrderAmount] = useState(0);
  const [printBillModalVisible, setPrintBillModalVisible] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountType, setDiscountType] = useState("percentage");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [menuItems, setMenuItems] = useState([]); // New state for menu items
  const [menuModalVisible, setMenuModalVisible] = useState(false); // New state for modal visibility
  const [billPopupVisible, setBillPopupVisible] = useState(false);
  const [addDiscount, setAddDiscount] = useState(0);
  const [realOrderAmount, setRealOrderAmount] = useState(0);
  const [serviceCharge, setServiceCharge] = useState("YES");
  const [searchQuery, setSearchQuery] = useState("");
  const [displayedItems, setDisplayedItems] = useState([]);
  const [billDiscount, setBillDiscount] = useState(0);

  const navigate = useNavigate();

  const pageStyle = `
  @page {
    size: 80mm 297mm;
    margin: 20mm;
  };

  @media all {
    .pagebreak {
      display: none;
    };

  @media print {
    .pagebreak {
      display: block;
      page-break-before: always;
    };
  }

  `;

  useEffect(() => {
    fetchTables();
  }, []);

  const handleButtonClick = () => {
    navigate("/order-management");
  };

  const fetchTables = async () => {
    try {
      const response = await axios.get("https://bkbordeau.debuxglobal.com/api/tables", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const allTables = [...response.data.availableTables, ...response.data.occupiedTables];
      setTables(allTables);
    } catch (error) {
      console.error("Error fetching tables:", error);
    }
  };

  const serviceChargeHandler = (value) => {
    setServiceCharge(value);
    console.log(value);
  };

  function getFormattedDate() {
    const today = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return today.toLocaleDateString("en-US", options);
  }

  // utils/timeUtils.js

  function getFormattedTime() {
    const now = new Date();
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    return now.toLocaleTimeString("en-US", options).replace("AM", "AM").replace("PM", "PM");
  }

  const fetchOrderDetailsforBill = async (orderId) => {
    try {
      const response = await axios.get(`https://bkbordeau.debuxglobal.com/api/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      setOrderDetails(response.data.items || []);
    } catch (error) {
      console.error("Error fetching order details:", error);
      message.error("Failed to fetch order details.");
    }
  };

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(`https://bkbordeau.debuxglobal.com/api/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      console.log(response.data);

      // Check if items exist and if the array length is greater than 0
      if (response.data && Array.isArray(response.data.items) && response.data.items.length > 0) {
        const totalSum = response.data.items.reduce((sum, item) => {
          return sum + item.originalPrice * item.quantity;
        }, 0);
        setRealOrderAmount(totalSum);
        setOrderDetails(response.data.items);
        setOngoingTableItems(response.data.items); // Set ongoingTableItems when table is selected
        setOrderId(orderId);
        console.log(response.data.totalAmount);
        setOrderAmount(response.data.totalAmount);

        setDiscount(response.data.discount);
        setBillDiscount(discount);
      }
      // Check for specific message indicating no ongoing order found
      else if (response.data && response.data.message === "No ongoing order found for this order ID") {
        setOrderDetails([]);
        setOngoingTableItems([]); // Clear ongoingTableItems when no items found
        setOrderId(orderId); // Set the orderId even if no items are found
        setOrderAmount(0); // Reset orderAmount if no details are found
      }
      // Handle case where there are no items but no specific message
      else {
        setOrderDetails([]);
        setOngoingTableItems([]); // Clear ongoingTableItems when no items found
        setOrderId(null);
        setOrderAmount(0); // Reset orderAmount if no details are found
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get("https://bkbordeau.debuxglobal.com/api/menus", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }); // Adjust the endpoint as necessary
      setMenuItems(response.data);
      setDisplayedItems(response.data); // Set displayed items to all items initially
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const handleTableSelect = (tableId) => {
    const selectedTable = tables.find((table) => table.table_id === tableId);
    if (selectedTable && selectedTable.is_occupied) {
      fetchOrderDetails(selectedTable.order_id);
      setOrderId(selectedTable.order_id);
    } else {
      setOngoingTableItems([]);
      setOrderDetails([]);
      setOrderAmount(0);
      setOrderId(null);
    }
  };

  const handleRemoveFromOrder = async (orderItemId) => {
    const itemToRemove = ongoingTableItems.find((item) => item.orderItemId === orderItemId);

    if (itemToRemove) {
      try {
        // Make the API call to delete the item
        await axios.delete(`https://bkbordeau.debuxglobal.com/api/orderItem/${orderItemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        message.success(`${itemToRemove.name} removed successfully!`);

        // Update ongoingTableItems state: decrease quantity or remove the item
        const updatedItems = ongoingTableItems
          .map((item) => {
            if (item.orderItemId === orderItemId) {
              if (item.quantity > 1) {
                // Reduce quantity by 1 if it's greater than 1
                return { ...item, quantity: item.quantity - 1 };
              } else {
                // Remove the item if the quantity is 1
                return null;
              }
            }
            return item;
          })
          .filter((item) => item !== null); // Remove any null items

        // Set the updated items list
        setOngoingTableItems(updatedItems);

        // Update the order amount
        const reducedAmount = itemToRemove.price;
        setOrderAmount((prevAmount) => prevAmount - reducedAmount);
        setRealOrderAmount((prevAmount) => prevAmount - reducedAmount);
      } catch (error) {
        console.error("Error removing item from order:", error);
        message.error("Failed to remove item. Please try again.");
      }
    }
  };

  const handleAddToOrder = async (item) => {
    try {
      const response = await axios.post(
        "https://bkbordeau.debuxglobal.com/api/orderItem",
        {
          order_id: orderId,
          menu_id: item.id,
          price: item.price,
          discount: 0,
          net_total: item.price,
          total_after_discount: item.price,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            // Add any other headers here if needed
          },
        }
      );

      let updatedItems;

      if (response.data && response.data.orderItemId) {
        // If the item is newly added (no existing item found)
        message.success(`${item.name} added successfully!`);

        const newItem = {
          ...item,
          quantity: 1,
          orderItemId: response.data.orderItemId, // Use the orderItemId from the response
        };

        updatedItems = [...ongoingTableItems, newItem];
      } else if (response.data && response.data.message === "Order item quantity updated successfully") {
        // If the item already exists and the quantity was updated
        message.success(`${item.name} quantity updated successfully!`);
        console.log(ongoingTableItems);

        updatedItems = ongoingTableItems.map((i) => (i.name === item.name ? { ...i, quantity: i.quantity + 1 } : i));
      } else {
        throw new Error("Unexpected response from the server.");
      }

      // Update the state after calculating the updated items
      setOngoingTableItems(updatedItems);
      console.log(ongoingTableItems);
      const newOrderAmount = updatedItems.reduce((sum, i) => sum + parseFloat(i.price) * i.quantity, 0);
      setOrderAmount(newOrderAmount);
      setRealOrderAmount(newOrderAmount);
    } catch (error) {
      console.error("Error adding/updating item:", error);
      message.error("Failed to add/update item. Please try again.");
    }
  };

  const calculateDiscount = () => {
    if (discountType === "percentage") {
      return (orderAmount * discountAmount) / 100;
    } else {
      return discountAmount;
    }
  };

  const handlePrintBill = () => {
    if (!orderId) {
      message.error("No order details available.");
      return;
    }
    setPrintBillModalVisible(true);
  };

  const handlePrintBillConfirm = async () => {
    try {
      console.log(orderDetails);
      console.log(serviceCharge);
      const discount = calculateDiscount();
      const finalAmount = orderAmount;
      console.log(finalAmount);

      if (!ongoingTableItems || ongoingTableItems.length === 0) {
        message.error("No items available for this order.");
        return;
      }

      await axios.post(
        "https://bkbordeau.debuxglobal.com/api/bills",
        {
          order_id: orderId,
          total_amount: finalAmount,
          discount: discount,
          payment_method: paymentMethod,
          seriveceCharge: serviceCharge,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            // Add any other headers here if needed
          },
        }
      );
      setPrintBillModalVisible(false);
      fetchOrderDetailsforBill(orderId);
      setBillPopupVisible(true);
      // Close the Print Bill modal and show the Bill popup
    } catch (error) {
      console.error("Error printing bill:", error);
      message.error("Failed to print bill. Please try again.");
    }
  };

  const handleDiscountChange = (value) => {
    if (!value) {
      value = 0;
    }
    setDiscountAmount(value);
  };

  const handleDiscountTypeChange = (value) => {
    setDiscountType(value);
    setDiscountAmount(0); // Reset the discount amount when the type changes
  };

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };

  const handleMenuModalOpen = () => {
    setMenuModalVisible(true);
    fetchMenuItems();
  };

  const handleMenuModalClose = () => {
    setMenuModalVisible(false);
  };

  const cancelOrder = async () => {
    try {
      await axios.delete(`https://bkbordeau.debuxglobal.com/api/cancelOrder/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      message.success("Order cancelled successfully!");
      setOngoingTableItems([]);
      setOrderDetails([]);
      setOrderAmount(0);
      setOrderId(null);
      fetchTables();
      setServiceCharge("YES");
      setBillDiscount(0);
      setDiscountType("percentage");
    } catch (error) {
      console.error("Error cancelling order:", error);
      message.error("Failed to cancel order. Please try again.");
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === "") {
      setDisplayedItems(menuItems); // Show all items if the search is cleared
    } else {
      setDisplayedItems(menuItems.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())));
    }
  };

  return (
    <div style={{ backgroundColor: "#F4F4F4", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
          marginTop: "-15px",
        }}
      >
        <Text
          style={{
            fontSize: "36px",
            fontWeight: "bold",
            color: "#000000",
            fontFamily: "Nunito",
          }}
        >
          Reserved Tables
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          onClick={handleButtonClick}
        >
          <Button
            style={{
              fontFamily: "Nunito",
              fontWeight: "700",
              width: "180px",
              height: "50px",
              marginRight: "10px",
            }}
          >
            <img src={Timer} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
            New Order
          </Button>
        </div>
      </div>
      <Row gutter={16}>
        {/* Left Column: Items in Selected Table */}
        <Col span={16}>
          <div
            style={{
              borderRadius: "10px",
              padding: "10px",
              height: "82vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h3 style={{ fontFamily: "Nunito", marginTop: "10px" }}>Items in Selected Table</h3>
              <Divider style={{ marginTop: "10px" }} />
              <Row gutter={16}>
                {ongoingTableItems.map((item) => (
                  <Col span={6} key={item.id}>
                    <ItemCard item={item} onRemove={handleRemoveFromOrder} />
                  </Col>
                ))}
                {orderId && (
                  <Col span={6}>
                    <Card
                      style={{
                        height: "326px",
                        width: "250px",
                        marginBottom: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        border: "2px dashed #F2890C",
                      }}
                      onClick={handleMenuModalOpen}
                    >
                      <PlusOutlined style={{ fontSize: "48px", color: "#F2890C" }} />
                      <p
                        style={{
                          marginTop: "10px",
                          fontFamily: "Nunito",
                          fontWeight: "700",
                          color: "#F2890C",
                        }}
                      >
                        Add Items
                      </p>
                    </Card>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </Col>

        {/* Right Column: Table Selection */}
        <Col span={8}>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "10px",
              height: "84vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <h3 style={{ fontFamily: "Nunito", marginTop: "10px" }}>Please Select The Table:</h3>
              <Divider style={{ marginTop: "10px" }} />

              {/* Scrollable container for table selection */}
              <div
                className="custom-scrollbar"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  overflowY: "auto", // Add vertical scroll
                  height: "470px", // Limit height to make it scrollable
                }}
              >
                {/* Sort tables to show the occupied (orange) ones first */}
                {tables
                  .sort((a, b) => b.is_occupied - a.is_occupied)
                  .map((table) => (
                    <Button
                      key={table.table_id}
                      style={{
                        backgroundColor: table.is_occupied ? "#F2890C" : "#09AA29",
                        color: "#fff",
                        height: "150px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: table.is_occupied ? "pointer" : "not-allowed",
                        textAlign: "center",
                        fontFamily: "Nunito",
                        fontWeight: "600",
                        width: "30%", // Adjust width to fit three buttons per row
                        marginBottom: "10px",
                        borderRadius: "10px",
                      }}
                      onClick={() => table.is_occupied && handleTableSelect(table.table_id)} // Only allow click if the table is occupied
                      disabled={!table.is_occupied} // Disable the button if the table is available
                    >
                      <h4
                        style={{
                          fontFamily: "Nunito",
                          fontWeight: "800",
                          fontSize: "60px",
                          padding: 0,
                          margin: 0,
                          marginTop: "-20px",
                        }}
                      >
                        {table.table_number}
                      </h4>
                      <p
                        style={{
                          fontFamily: "Nunito",
                          fontWeight: "800",
                          fontSize: "16px",
                          padding: 0,
                          margin: 0,
                          marginTop: "-30px",
                        }}
                      >
                        {table.is_occupied ? "Reserved" : "Available"}
                      </p>
                    </Button>
                  ))}
              </div>
            </div>

            {orderId && (
              <>
                <div
                  style={{
                    fontFamily: "Nunito",
                    marginTop: "auto", // Push this section to the bottom
                    fontWeight: "600",
                  }}
                >
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                      marginTop: "20px",
                    }}
                  >
                    <span>Subtotal:</span>
                    <span>{orderAmount} LKR</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <span>Service Charge 10%:</span>
                    <span>{(orderAmount * 0.1).toFixed(2)} LKR</span> {/* Assuming 10% tax */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                      fontWeight: "bold",
                      fontSize: "22px",
                    }}
                  >
                    <span>Payable Amount:</span>
                    <span>{(orderAmount + orderAmount * 0.1).toFixed(2)} LKR</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "16px",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={cancelOrder}
                    style={{
                      backgroundColor: "#F2890C",
                      borderColor: "#F2890C",
                      flex: 1,
                      padding: "10px",
                      marginRight: "15px",
                      fontFamily: "Nunito",
                      fontWeight: "600",
                      height: "50px",
                    }}
                  >
                    <img src={Rights} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
                    Cancel Order
                  </Button>
                  <Button
                    type="primary"
                    onClick={handlePrintBill}
                    style={{
                      backgroundColor: "#09AA29",
                      borderColor: "#09AA29",
                      flex: 1,
                      fontFamily: "Nunito",
                      fontWeight: "600",
                      height: "50px",
                    }}
                  >
                    <img src={PointOfSale} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
                    Print Bill
                  </Button>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Modal
        visible={billPopupVisible}
        footer={null}
        onCancel={() => {
          setBillPopupVisible(false);
          setOngoingTableItems([]); // Clear the selected items
          fetchTables();
          setOrderId(null); // Refresh the tables after printing the bill
        }}
        bodyStyle={{ padding: 0 }}
      >
        <div>
          <ReactToPrint
            trigger={() => <Button style={{ margin: "10px" }}>Print this out!</Button>}
            content={() => document.getElementById("billToPrint")}
            pageStyle={pageStyle}
          />
          <div id="billToPrint">
            <Bill
              items={orderDetails}
              total={realOrderAmount.toFixed(2)}
              NetTotal={orderAmount.toFixed(2)}
              table="1" // Replace with actual table number if available
              order={orderId}
              discount={
                discountType === "percentage"
                  ? parseFloat((orderAmount / 100) * discountAmount).toFixed(2) + " LKR"
                  : calculateDiscount().toFixed(2)
              }
              date={new Date().toLocaleString()}
              service_Charge={serviceCharge}
              isPrint={true}
            />
          </div>
        </div>
      </Modal>

      {/* Print Bill Modal */}
      <Modal
        visible={printBillModalVisible}
        onCancel={() => {
          setPrintBillModalVisible(false);
          setServiceCharge("YES");
        }}
        centered={true}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setPrintBillModalVisible(false);
              setServiceCharge("YES");
            }}
            style={{
              border: "4px solid #E1E1E1",
              backgroundColor: "#FFFFFF",
              color: "#000000",
              fontFamily: "Nunito",
              fontSize: "16px",
              height: "47px",
              fontWeight: "700",
              transition: "background-color 0.3s",
            }}
          >
            Cancel
          </Button>,
          <Button
            key="print"
            onClick={handlePrintBillConfirm}
            style={{
              backgroundColor: "#000000",
              color: "#FFFFFF",
              fontFamily: "Nunito",
              fontSize: "16px",
              fontWeight: "700",
              height: "45px",
              transition: "background-color 0.3s",
              border: "none", // Removes default border
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#F2890C")}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#000000")}
          >
            Print Bill
          </Button>,
        ]}
      >
        <Text style={{ fontFamily: "Nunito", fontSize: "28px", fontWeight: "800" }}>Bill Print Details</Text>
        <br />
        <br />
        <Text style={{ fontFamily: "Nunito", fontSize: "22px", fontWeight: "700", paddingTop: "20px" }}>
          Payment Method:
        </Text>
        <Segmented
          options={[
            { label: "Paying by Cash", value: "cash" },
            { label: "Paying by Card", value: "card" },
          ]}
          value={paymentMethod}
          onChange={handlePaymentMethodChange}
          block={true}
          size="large"
          style={{
            width: "100%",
            height: "50px",
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: "700",
            textAlign: "center",
            marginBottom: "20px",
            padding: "4px",
            borderRadius: "15px",
          }}
        />
        <Text style={{ fontFamily: "Nunito", fontSize: "22px", fontWeight: "700", paddingTop: "20px" }}>
          Discount Type:
        </Text>
        <Segmented
          options={[
            { label: "Percentage Discount", value: "percentage" },
            { label: "Fixed Discount", value: "fixed" },
          ]}
          value={discountType}
          onChange={handleDiscountTypeChange}
          block={true}
          size="large"
          style={{
            width: "100%",
            height: "50px",
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: "700",
            textAlign: "center",
            padding: "4px",
            borderRadius: "15px", // Centers the entire block // Centers the items vertically
          }}
        />
        {discountType === "percentage" && (
          <p>
            <InputNumber
              min={0}
              max={100} // Restricts the maximum input to 100
              defaultValue={0}
              onChange={(value) => {
                if (value <= 100) {
                  handleDiscountChange(value); // Only allow the value if it's less than or equal to 100
                }
              }}
              formatter={(value) => `${value}%`} // Ensures the % is always displayed
              parser={(value) => value.replace("%", "")} // Removes % for internal calculations
              style={{
                width: "100%",
                marginTop: "10px",
                fontFamily: "Nunito",
                fontSize: "22px",
                fontWeight: "500",
                borderRadius: "15px",
                border: "4px solid #F4F4F7",
              }}
              inputMode="numeric" // Restrict input to numbers only
            />
          </p>
        )}
        {discountType === "fixed" && (
          <p>
            <InputNumber
              min={0}
              defaultValue={0}
              onChange={handleDiscountChange} // No max for fixed value, allow numbers only
              formatter={(value) => `LKR ${value}`} // Ensures LKR is always displayed
              parser={(value) => value.replace("LKR ", "")} // Removes LKR for internal calculations
              style={{
                width: "100%",
                marginTop: "10px",
                fontFamily: "Nunito",
                fontSize: "22px",
                fontWeight: "500",
                borderRadius: "15px",
                border: "4px solid #F4F4F7",
              }}
              inputMode="numeric" // Restrict input to numbers only
            />
          </p>
        )}

        <br />
        <Text style={{ fontFamily: "Nunito", fontSize: "22px", fontWeight: "700", paddingTop: "20px" }}>
          Service Charge:
        </Text>
        <Segmented
          options={[
            { label: "YES", value: "YES" },
            { label: "NO", value: "NO" },
          ]}
          value={serviceCharge}
          onChange={serviceChargeHandler}
          block={true}
          size="large"
          style={{
            width: "100%",
            height: "50px",
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: "700",
            textAlign: "center",
            marginBottom: "20px",
            padding: "4px",
            borderRadius: "15px",
          }}
        />
        <Divider />
        <div
          style={{
            fontFamily: "'Nunito', sans-serif",
            border: "2px solid #EDEDED",
            padding: "16px",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          {/* Bill Header */}
          <div style={{ marginBottom: "16px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text style={{ fontFamily: "Nunito", fontSize: "16px", fontWeight: "700" }}>Bill Number</Text>
              <Text style={{ fontFamily: "Nunito", fontSize: "16px", fontWeight: "700" }}>{getFormattedDate()}</Text>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
              <Text style={{ fontFamily: "Nunito", fontSize: "14px", fontWeight: "700", color: "#686D82" }}>
                #{orderId}
              </Text>
              <Text style={{ fontFamily: "Nunito", fontSize: "14px", fontWeight: "700", color: "#686D82" }}>
                {getFormattedTime()}
              </Text>
            </div>
          </div>
          {/* Bill Body */}
          <div style={{ borderTop: "1px solid #EDEDED", paddingTop: "16px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "2px" }}>
              <Text style={{ fontFamily: "Nunito", fontSize: "18px", fontWeight: "700" }}>Sub Total:</Text>
              <Text style={{ fontFamily: "Nunito", fontSize: "16px", fontWeight: "700", color: "#686D82" }}>
                {realOrderAmount.toFixed(2)} LKR
              </Text>
            </div>
            {serviceCharge === "YES" && (
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "2px" }}>
                <Text style={{ fontFamily: "Nunito", fontSize: "18px", fontWeight: "700" }}>Service Charge:</Text>
                <Text style={{ fontFamily: "Nunito", fontSize: "16px", fontWeight: "700", color: "#686D82" }}>
                  {(orderAmount * 0.1).toFixed(2)} LKR
                </Text>
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "2px" }}>
              <Text style={{ fontFamily: "Nunito", fontSize: "18px", fontWeight: "700" }}>Discount Amount:</Text>
              <Text style={{ fontFamily: "Nunito", fontSize: "16px", fontWeight: "700", color: "#686D82" }}>
                {(-ongoingTableItems.reduce((sum, item) => sum + (item.discount || 0), 0)).toFixed(2)} LKR
              </Text>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "2px" }}>
              {discountType === "percentage" ? (
                <Text style={{ fontFamily: "Nunito", fontSize: "18px", fontWeight: "700" }}>
                  Additional Discount -({discountAmount}%):
                </Text>
              ) : (
                <Text style={{ fontFamily: "Nunito", fontSize: "18px", fontWeight: "700" }}>Additional Discount:</Text>
              )}
              <Text style={{ fontFamily: "Nunito", fontSize: "16px", fontWeight: "700", color: "#686D82" }}>
                -{calculateDiscount().toFixed(2)} LKR
              </Text>
            </div>

            {/* Total Section */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "2px solid #EDEDED",
                backgroundColor: "#EDEDED",
                paddingTop: "16px",
                paddingBottom: "16px",
                marginBottom: "-16px",
                paddingLeft: "16px",
                paddingRight: "16px",
                marginTop: "16px",
                marginRight: "-16px",
                marginLeft: "-16px",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              <Text style={{ fontFamily: "Nunito", fontSize: "22px", fontWeight: "800" }}>Total:</Text>
              <Text style={{ fontFamily: "Nunito", fontSize: "22px", fontWeight: "800" }}>
                {serviceCharge === "YES"
                  ? (orderAmount + orderAmount * 0.1 - calculateDiscount()).toFixed(2)
                  : (orderAmount - calculateDiscount()).toFixed(2)}{" "}
                LKR
              </Text>
            </div>
          </div>
        </div>
      </Modal>

      {/* Menu Items Modal */}
      <Modal
        visible={menuModalVisible}
        onCancel={handleMenuModalClose}
        footer={null}
        width={1200}
        bodyStyle={{ backgroundColor: "#F4F4F4", padding: 10, margin: 0 }}
      >
        <Input
          placeholder="Search items"
          value={searchQuery}
          className="custom-search-input"
          onChange={(e) => handleSearch(e.target.value)}
          style={{
            width: "100%",
            fontFamily: "Nunito",
            fontWeight: "600",
            borderRadius: "10px",
            padding: "10px",
          }}
        />
        <Row gutter={16}>
          {displayedItems.map((item) => (
            <Col span={6} key={item.id} style={{ marginTop: "20px" }}>
              <ModalCard
                item={item}
                onAdd={() => {
                  handleAddToOrder(item); // Add item to the order
                  handleMenuModalClose(); // Close the modal after adding the item
                }} // Add item to the order on click
              />
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  );
};

const ModalCard = ({ item, onAdd }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Card
      style={{
        height: "326px",
        width: "250px",
        marginBottom: "20px",
        cursor: "pointer",
        border: hovered ? "3px solid #F2890C" : "2px solid transparent",
        transition: "border 0.3s, background-color 0.3s",
        backgroundColor: hovered ? "#F2890C1A" : "white",
        textAlign: "center",
        position: "relative",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => onAdd(item.orderItemId)}
      hoverable
    >
      <img src={item.image || Food} alt={item.name} style={{ width: "100%", height: "200px", objectFit: "cover" }} />
      <h4
        style={{
          margin: 0,
          fontFamily: "Nunito",
          color: hovered ? "#F2890C" : "#000",
          fontWeight: "600",
        }}
      >
        {item.name}
      </h4>
      <p
        style={{
          color: hovered ? "#F2890C" : "#000",
          fontFamily: "Nunito",
          fontWeight: "700",
        }}
      >
        {item.price} LKR
      </p>
    </Card>
  );
};
const ItemCard = ({ item, onRemove }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Card
      style={{
        height: "326px",
        width: "250px",
        marginBottom: "20px",
        cursor: "pointer",
        border: hovered ? "3px solid #F2890C" : "2px solid transparent",
        transition: "border 0.3s, background-color 0.3s",
        backgroundColor: hovered ? "#F2890C1A" : "white",
        textAlign: "center",
        position: "relative",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      hoverable
    >
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 10,
          backgroundColor: "#F2890C",
          color: "white",
          borderRadius: "5px",
          padding: "2px 6px",
          fontFamily: "Nunito",
          fontWeight: "700",
          fontSize: "16px",
          zIndex: 2,
        }}
      >
        {item.quantity}x
      </div>
      <CloseCircleOutlined
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          fontSize: "20px",
          color: "#F2890C",
          cursor: "pointer",
          zIndex: 2,
        }}
        onClick={() => onRemove(item.orderItemId)} // Correctly pass the orderItemId here
      />
      <img src={item.image || Food} alt={item.name} style={{ width: "100%", height: "200px", objectFit: "cover" }} />
      <h4
        style={{
          margin: 0,
          fontFamily: "Nunito",
          color: hovered ? "#F2890C" : "#000",
          fontWeight: "600",
        }}
      >
        {item.name}
      </h4>
      <p
        style={{
          color: hovered ? "#F2890C" : "#000",
          fontFamily: "Nunito",
          fontWeight: "700",
        }}
      >
        {item.price} LKR
      </p>
    </Card>
  );
};

export default OngoingOrders;
