import React from "react";
import bordeaulogo from "../assets/BillImages/bordeaulogo.png";
import { useAuth } from "../context/AuthContext";

// Reusable styled components
const Card = ({ children, className }) => (
  <div className={`bg-white shadow-md rounded-lg ${className}`}>{children}</div>
);
const CardHeader = ({ children, className }) => <div className={`p-4 border-b ${className}`}>{children}</div>;
const CardContent = ({ children, className }) => <div className={`p-4 ${className}`}>{children}</div>;
const CardFooter = ({ children, className }) => <div className={`p-4 border-t ${className}`}>{children}</div>;

export default function POSReceipt({
  items,
  total,
  table,
  order,
  date,
  isPrint,
  NetTotal,
  discount,
  cashierName,
  service_Charge,
}) {
  const { user } = useAuth();
  const parsedTotal = parseFloat(total) || 0;
  const parsedNetTotal = parseFloat(NetTotal) || 0;
  const parsedDiscount = parseFloat(discount) || 0;
  const result = (parsedNetTotal - parsedDiscount).toFixed(2);

  // Calculate service charge based on service_Charge
  const serviceCharge = service_Charge === "YES" ? parsedNetTotal * 0.1 : "0.00";

  // Calculate total item discounts
  const totalItemDiscount = items.reduce((sum, item) => sum + (item.discount || 0), 0).toFixed(2);
  const newsvtotal = parsedTotal * 0.1;

  return (
    <Card className="w-80 mx-auto font-mono text-xs leading-tight">
      <CardHeader className="text-center pb-2">
        <div className="text-xl font-bold flex items-center justify-center mx-auto">
          <img src={bordeaulogo} alt="Bord Eau Logo" className="w-20 h-6 mx-auto mb-2" />
        </div>
        <h2 className="font-bold">Balapitiya</h2>
        <p>212 Galle Rd, Balapitiya</p>
        <p>Open 9AM - Close 8PM</p>
        <p>Tel No: 077-756-6503</p>
      </CardHeader>
      <CardContent className="space-y-2">
        <div>
          {/* Displaying receipt details */}
          <p>Order: Dine In</p>
          <p>{`Table No: ${table}`}</p>
          <p>{`Order No: ${order}`}</p>
          <p>{`Cashier: ${user.userName || "N/A"}`}</p>
          <p>{date}</p>
        </div>
        <div>
          <p>Item Description</p>
          <p className="flex justify-between">
            <span>Qty</span>
            <span>U/Price</span>
            <span>%</span>
            <span>Discount</span>
            <span>Amount</span>
          </p>
          {items.map((item, index) => (
            <div key={index} className="mt-1">
              <p style={{ fontSize: "16px" }}>{item.name}</p>
              <p className="flex justify-between">
                <span>{item.quantity.toFixed(0)}</span>
                <span>{item.originalPrice.toFixed(2)}</span>
                <span>
                  {(
                    (item.discount / (item.discount + (item.quantity * item.price - (item.discountAmount || 0)))) *
                    100
                  ).toFixed(2) || "0.00"}
                  %
                </span>
                <span>{item.discount ? item.discount.toFixed(2) : "0.00"}</span>
                <span>{(item.quantity * item.price - (item.discountAmount || 0)).toFixed(2)}</span>
              </p>
            </div>
          ))}
        </div>
        <div className="border-t border-black pt-2">
          <p className="flex justify-between">
            <span>Sub Total:</span>
            <span>{total.toFixed(2)}</span>
          </p>
          {service_Charge === "YES" && (
            <p className="flex justify-between">
              <span>Service Charge:</span>
              <span>{serviceCharge.toFixed(2)}</span>
            </p>
          )}
          {/* Adding total item discount to the receipt */}
          <p className="flex justify-between">
            <span>Total Item Discounts:</span>
            <span>- {totalItemDiscount}</span>
          </p>
          <p className="flex justify-between">
            <span>Additional Discount:</span>
            <span>- {parsedDiscount.toFixed(2)}</span>
          </p>
          <p className="flex justify-between">
            <span>
              <b>Net Total:</b>
            </span>
            <span>
              {service_Charge === "YES"
                ? (parsedTotal - totalItemDiscount - parsedDiscount + serviceCharge).toFixed(2)
                : (parsedTotal - totalItemDiscount - parsedDiscount).toFixed(2)}
            </span>
          </p>
          <br />
          <p>Total Quantity: {items.reduce((sum, item) => sum + item.quantity, 0).toFixed(0)}</p>
        </div>
      </CardContent>
      <CardFooter className="text-center pt-0">
        <p className="w-full">Thank You</p>
      </CardFooter>
    </Card>
  );
}
