import React, { useEffect, useState } from "react";
import { Card, List, Typography } from "antd";
import axios from "axios";

const { Text, Title } = Typography;

const DashSec4 = () => {
  const [topSellingItems, setTopSellingItems] = useState([]);

  useEffect(() => {
    fetchTopSellingItems();
  }, []);

  const fetchTopSellingItems = async () => {
    try {
      const response = await axios.get("https://bkbordeau.debuxglobal.com/api/topselling", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const top5Items = response.data.slice(0, 5); // Only take the top 5 items
      setTopSellingItems(top5Items);
    } catch (error) {
      console.error("Error fetching top-selling items:", error);
    }
  };

  return (
    <Card
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        padding: "20px",
        height: "608px",
      }}
    >
      <Title level={3} style={{ fontFamily: "Nunito", fontWeight: "700", marginTop: "20px" }}>
        Top 5 Best Selling Items
      </Title>
      <List
        itemLayout="horizontal"
        dataSource={topSellingItems}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={<Text style={{ fontFamily: "Nunito", fontSize: "18px" }}>{item["Item Name"]}</Text>}
              description={
                <Text
                  style={{
                    fontFamily: "Nunito",
                    fontSize: "16px",
                    color: "#909090",
                  }}
                >
                  Quantity Sold: {item["Total Quantity Ordered"]}
                </Text>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default DashSec4;
