import React, { useEffect, useState } from "react";
import { Table, Button, Modal, message, Typography } from "antd";
import axios from "axios";
import moment from "moment";
import ReactToPrint from "react-to-print";
import PointOfSale from "../assets/SideBarImages/PointOfSale.png";
import Bill from "../assets/TransBill";
import { useNavigate } from "react-router-dom";
import Timer from "../assets/SideBarImages/Timer.png";

const { Text } = Typography;

const Transactions = () => {
  const [data, setData] = useState([]);
  const [billData, setBillData] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [billPopupVisible, setBillPopupVisible] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderAmount, setOrderAmount] = useState(0);
  const [realOrderAmount, setRealOrderAmount] = useState(0);
  const [billDate, setBillDate] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [serviceCharge, setServiceCharge] = useState("NO"); // Default to "NO"

  const navigate = useNavigate();

  const pageStyle = `
    @page {
        size: 80mm 297mm;
        margin: 20mm;
    };

    @media all {
        .pagebreak {
            display: none;
        };

    @media print {
        .pagebreak {
            display: block;
            page-break-before: always;
        };
    }
    `;

  useEffect(() => {
    fetchBills();
  }, []);

  useEffect(() => {
    if (billData.length > 0 && selectedOrderId) {
      fetchOrderDetails(selectedOrderId);
    }
  }, [billData, selectedOrderId]);

  const fetchBills = async () => {
    try {
      const response = await axios.get("https://bkbordeau.debuxglobal.com/api/bills", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // Add any other headers here if needed
        },
      });
      setData(response.data);
      setBillData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleButtonClick = () => {
    navigate("/order-management");
  };

  const handleOnButtonClick = () => {
    navigate("/ongoing-orders");
  };

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(`https://bkbordeau.debuxglobal.com/api/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      const totalSum = response.data.items.reduce((sum, item) => {
        return sum + item.originalPrice * item.quantity;
      }, 0);

      setRealOrderAmount(totalSum);
      setOrderDetails(response.data.items || []);
      setOrderAmount(response.data.totalAmount || 0);

      // Make sure billData is available before finding the corresponding bill
      if (billData && billData.length > 0) {
        const correspondingBill = billData.find((bill) => bill.order_id === orderId);
        console.log(correspondingBill); // Check if correspondingBill is found

        const serviceCharge = correspondingBill ? correspondingBill.servicecharge : "NO";
        setServiceCharge(serviceCharge);
      } else {
        console.log("Bill data not available");
        setServiceCharge("NO");
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
      message.error("Failed to fetch order details.");
    }
  };

  const handlePrintBill = (orderId) => {
    setSelectedOrderId(orderId);

    const bill = data.find((bill) => bill.order_id === orderId);
    const dis = data.find((dis) => dis.order_id === orderId);

    if (bill) {
      setBillDate(bill.created_at);
      setDiscount(dis.discount);
      fetchOrderDetails(orderId); // Fetch order details
      setBillPopupVisible(true); // Show the bill print popup
    } else {
      message.error("No bill found for this order.");
    }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (method) => (
        <span style={getPaymentMethodStyle(method)}>
          <span
            style={{
              width: "8px",
              height: "8px",
              backgroundColor: method === "cash" ? "#409261" : "#D98634",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "8px",
            }}
          />
          {method.toUpperCase()}
        </span>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (amount) => `LKR ${amount}`,
      sorter: (a, b) => a.total_amount - b.total_amount,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (discount) => `LKR ${discount}`,
    },
    {
      title: "Net Total",
      dataIndex: "net_total",
      key: "net_total",
      render: (netTotal) => `LKR ${netTotal}`,
      sorter: (a, b) => a.net_total - b.net_total,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "date",
      render: (date) => moment(date).format("YYYY/MM/DD"),
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "time",
      render: (date) => moment(date).format("HH:mm:ss"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          style={{
            backgroundColor: "#09AA29",
            borderColor: "#09AA29",
            fontFamily: "Nunito",
            fontWeight: "600",
          }}
          onClick={() => handlePrintBill(record.order_id)}
        >
          <img src={PointOfSale} width={16} height={16} alt="Print Bill" style={{ marginRight: "8px" }} />
          Print Bill
        </Button>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
          marginTop: "-15px",
        }}
      >
        <Text
          style={{
            fontSize: "36px",
            fontWeight: "bold",
            color: "#000000",
            fontFamily: "Nunito",
          }}
        >
          Transactions
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              fontFamily: "Nunito",
              fontWeight: "700",
              width: "180px",
              height: "50px",
              marginRight: "10px",
              border: isHovered ? "1px solid #F2890D" : "1px solid transparent",
              color: isHovered ? "#F2890D" : "inherit",
              padding: "10px",
              cursor: "pointer",
              transition: "border-color 0.3s ease", // Adding smooth transition
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleButtonClick}
          >
            <img src={Timer} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
            New Order
          </Button>
          <Button
            style={{
              fontFamily: "Nunito",
              fontWeight: "700",
              width: "180px",
              height: "50px",
              marginRight: "10px",
            }}
            onClick={handleOnButtonClick}
          >
            <img src={Timer} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
            On Going Orders
          </Button>
        </div>
      </div>
      <Table columns={columns} dataSource={data} rowKey="id" style={{ fontFamily: "Nunito", borderRadius: "15px" }} />

      <Modal
        visible={billPopupVisible}
        footer={null}
        onCancel={() => setBillPopupVisible(false)}
        bodyStyle={{ padding: 0 }}
      >
        <div>
          <ReactToPrint
            trigger={() => <Button style={{ margin: "10px" }}>Print this out!</Button>}
            content={() => document.getElementById("billToPrint")}
            pageStyle={pageStyle}
          />
          <div id="billToPrint">
            <Bill
              items={orderDetails}
              total={realOrderAmount}
              NetTotal={orderAmount}
              table="1" // Replace with actual table number if available
              order={selectedOrderId}
              discount={parseFloat(discount).toFixed(2) + " LKR"}
              date={moment(billDate).format("YYYY/MM/DD HH:mm:ss")}
              isPrint={true}
              service_Charge={serviceCharge}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const getPaymentMethodStyle = (method) => {
  return {
    backgroundColor: method === "cash" ? "#E9FFEF" : "#FFF2DD",
    color: method === "cash" ? "#409261" : "#D98634",
    borderRadius: "15px",
    padding: "2px 8px",
    display: "inline-block",
    textAlign: "center",
    position: "relative",
    width: "100px",
    height: "30px",
  };
};

export default Transactions;
