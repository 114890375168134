import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Row, Col, Divider, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import Logo from "../assets/BillImages/bordeaulogo.png";

const { Title } = Typography;

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/"); // Redirect if already authenticated
    }
  }, [isAuthenticated, navigate]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post("https://bkbordeau.debuxglobal.com/api/login", values);
      if (response.data.success) {
        const { accessToken, refreshToken, userName, userProfilePicture, userPosition } = response.data;
        const userData = { userName, userProfilePicture, userPosition };
        login(accessToken, refreshToken, userData); // Pass user data to login function
        message.success("Login successful!");
        navigate("/"); // Redirect to a protected route
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Login failed!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ overflow: "hidden", width: "100vw" }}>
      <Row gutter={16} align="middle">
        <Col span={16}>
          <div
            style={{
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </Col>
        <Col span={8}>
          <div style={{ padding: "50px", float: "left" }}>
            <Form onFinish={onFinish}>
              <img src={Logo} alt="CLA GREAT MANAGERS" />
              <br />
              <Title level={2} style={{ textAlign: "center", color: "black" }}>
                Login to your Account
              </Title>
              <Divider orientation="center">
                <Title level={4} style={{ color: "#A1A1A1" }}>
                  Sign in with Username
                </Title>
              </Divider>
              <Form.Item name="username" rules={[{ required: true, message: "Please input your username!" }]}>
                <Input placeholder="Username" />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: "Please input your password!" }]}>
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} style={{ width: "100%" }}>
                  Login
                </Button>
              </Form.Item>
              <Divider />
              <Title style={{ fontSize: "12px", float: "right" }}>
                <a href="mailto:support@debuxglobal.com">Forgot Password</a>
              </Title>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginForm;
