import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Card, Divider, Select } from "antd";
import moment from "moment";

const { Text } = Typography;
const { Option } = Select;

const DashSec2 = () => {
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1); // Default to current month
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [numberOfSales, setNumberOfSales] = useState(0);
  const [dailyEarnings, setDailyEarnings] = useState(0);

  const [earningsChange, setEarningsChange] = useState(0);
  const [salesChange, setSalesChange] = useState(0);
  const [dailyChange, setDailyChange] = useState(0);

  const fetchBills = async (month) => {
    try {
      const response = await fetch(`https://bkbordeau.debuxglobal.com/api/monthreport/${month}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await response.json();

      // Group bills by date
      const billsByDate = data.reduce((acc, bill) => {
        const date = new Date(bill.created_at).toLocaleDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(bill);
        return acc;
      }, {});

      // Get selected and previous month data
      const currentMonth = selectedMonth;
      const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;

      const billsThisMonth = data.filter((bill) => {
        const billDate = new Date(bill.created_at);
        return billDate.getMonth() + 1 === currentMonth;
      });

      const billsLastMonth = data.filter((bill) => {
        const billDate = new Date(bill.created_at);
        return billDate.getMonth() + 1 === previousMonth;
      });

      // Calculate sales count and earnings for selected and previous months
      const salesThisMonth = billsThisMonth.length;
      const salesLastMonth = billsLastMonth.length;

      const earningsThisMonth = billsThisMonth.reduce((sum, bill) => sum + bill.net_total, 0);
      const earningsLastMonth = billsLastMonth.reduce((sum, bill) => sum + bill.net_total, 0);

      // Calculate today's and yesterday's earnings and sales
      const today = new Date().toLocaleDateString();
      const yesterday = new Date(Date.now() - 86400000).toLocaleDateString();

      const todayEarnings = billsByDate[today]?.reduce((sum, bill) => sum + bill.net_total, 0) || 0;
      const yesterdayEarnings = billsByDate[yesterday]?.reduce((sum, bill) => sum + bill.net_total, 0) || 0;

      // Calculate percentage changes
      const earningsPercentageChange =
        earningsLastMonth === 0 ? 0 : ((earningsThisMonth - earningsLastMonth) / earningsLastMonth) * 100;
      const salesPercentageChange =
        salesLastMonth === 0 ? 0 : ((salesThisMonth - salesLastMonth) / salesLastMonth) * 100;
      const dailyPercentageChange =
        yesterdayEarnings === 0 ? 0 : ((todayEarnings - yesterdayEarnings) / yesterdayEarnings) * 100;

      setTotalEarnings(earningsThisMonth);
      setNumberOfSales(salesThisMonth);
      setDailyEarnings(todayEarnings);

      setEarningsChange(earningsPercentageChange);
      setSalesChange(salesPercentageChange);
      setDailyChange(dailyPercentageChange);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchBills(selectedMonth);
  }, [selectedMonth]);

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const formatNumber = (num) => {
    if (num >= 1e9) {
      // For billions and above, show with 'B'
      return (num / 1e9).toFixed(1) + "B";
    } else if (num >= 1e6) {
      // For millions, show with 'M'
      return (num / 1e6).toFixed(1) + "M";
    } else if (num >= 1e3) {
      // For thousands, show with 'K'
      return (num / 1e3).toFixed(1) + "K";
    } else {
      // Small numbers, format with commas
      return num.toLocaleString("en-IN");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        padding: "20px",
        height: "380px",
      }}
    >
      <Row gutter={[16, 16]} align="middle" justify="space-between">
        <Col>
          <Text
            style={{
              fontFamily: "Nunito",
              fontSize: "24px",
              fontWeight: "700",
            }}
          >
            Statistics
          </Text>
        </Col>
        <Col>
          <Select value={selectedMonth} onChange={handleMonthChange} style={{ fontFamily: "Nunito", width: 120 }}>
            {moment.months().map((month, index) => (
              <Option key={index + 1} value={index + 1}>
                {month}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "70px" }}>
        <Col span={1}></Col>
        <Col span={7}>
          <Card style={{ border: "none", marginLeft: "-20px" }}>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "24px",
                fontWeight: "700",
                color: "#909090",
                display: "block",
              }}
            >
              Total Earnings
            </Text>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "38px",
                fontWeight: "700",
                marginLeft: "-20px",
              }}
            >
              {formatNumber(totalEarnings)} {/* Use custom formatter */}
            </Text>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "18px",
                fontWeight: "700",
                color: "#909090",
                paddingLeft: "5px",
              }}
            >
              LKR
            </Text>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "16px",
                color: earningsChange >= 0 ? "green" : "red",
                display: "block",
                fontWeight: "700",
              }}
            >
              {earningsChange >= 0 ? `+` : `-`} {Math.abs(earningsChange).toFixed(2)}%
            </Text>
          </Card>
        </Col>
        <Col span={1}>
          <Divider type="vertical" style={{ height: "100px", marginTop: "30px" }} />
        </Col>
        <Col span={7} style={{ textAlign: "center" }}>
          <Card style={{ border: "none" }}>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "24px",
                fontWeight: "700",
                color: "#909090",
                display: "block",
              }}
            >
              Number of Sales
            </Text>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "38px",
                fontWeight: "700",
              }}
            >
              {numberOfSales.toLocaleString()}
            </Text>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "18px",
                fontWeight: "700",
                color: "#909090",
                paddingLeft: "5px",
              }}
            >
              QTY
            </Text>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "16px",
                color: salesChange >= 0 ? "green" : "red",
                display: "block",
                fontWeight: "700",
              }}
            >
              {salesChange >= 0 ? `+` : `-`} {Math.abs(salesChange).toFixed(2)}%
            </Text>
          </Card>
        </Col>
        <Col span={1}>
          <Divider type="vertical" style={{ height: "100px", marginTop: "30px" }} />
        </Col>
        <Col span={7} style={{ textAlign: "center" }}>
          <Card style={{ border: "none" }}>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "24px",
                fontWeight: "700",
                color: "#909090",
                display: "block",
              }}
            >
              Daily Sales
            </Text>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "38px",
                fontWeight: "700",
                marginLeft: "-15px",
              }}
            >
              {formatNumber(dailyEarnings)}
            </Text>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "18px",
                fontWeight: "700",
                color: "#909090",
                paddingLeft: "5px",
              }}
            >
              LKR
            </Text>
            <Text
              style={{
                fontFamily: "Nunito",
                fontSize: "16px",
                color: dailyChange >= 0 ? "green" : "red",
                display: "block",
                fontWeight: "700",
              }}
            >
              {dailyChange >= 0 ? `+` : `-`} {Math.abs(dailyChange).toFixed(2)}%
            </Text>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashSec2;
