import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Input,
  Button,
  Select,
  Form,
  Typography,
  message,
  Image,
  Upload,
  Modal,
  Row,
  Col,
  Card,
  Divider,
  Carousel,
} from "antd";
import { CaretRightOutlined, CaretLeftOutlined, EditOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { storage } from "../components/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Plus from "../assets/SideBarImages/plus.png";
import Food from "../assets/SideBarImages/foodplaceholder.png";

const { Option } = Select;
const { Title, Text } = Typography;

const MenuItemForm = () => {
  const [branches, setBranches] = useState([]);
  const [categories, setCategories] = useState([]);
  const [optcategories, setOptCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    category_id: "",
    branch_id: "",
    image: "",
  });
  const [editItemId, setEditItemId] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get("https://bkbordeau.debuxglobal.com/api/menus", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setMenuItems(response.data);
      setDisplayedItems(response.data);
      setDisplayedItems(response.data); // Initially display all items
      const categories = ["All", ...new Set(response.data.map((item) => item.category_name))];
      setCategories(categories);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get("https://bkbordeau.debuxglobal.com/api/branches", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        setBranches(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://bkbordeau.debuxglobal.com/api/categories", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        setOptCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchBranches();
    fetchCategories();
    fetchMenuItems();
  }, []);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = ({ file }) => {
    setImageFile(file);
  };

  const handleSubmit = async () => {
    try {
      let uploadedImageUrl = "";

      // Upload the image if available
      if (imageFile) {
        const storageRef = ref(storage, `uploads/${imageFile.name}`);
        await uploadBytes(storageRef, imageFile);
        uploadedImageUrl = await getDownloadURL(storageRef);

        // If image upload fails
        if (!uploadedImageUrl) {
          message.error("Image upload failed. Please try again.");
          return;
        }
      } else {
        uploadedImageUrl = `https://firebasestorage.googleapis.com/v0/b/debuxpos.appspot.com/o/uploads%2F79-798754_hoteles-y-centros-vaca.png?alt=media&token=14fcd828-e291-4fb1-87aa-dcbff84af8dc`;
      }

      // If editing and no new image is uploaded, retain the current image URL
      if (editItemId && !imageFile) {
        uploadedImageUrl = formData.image; // Use the existing image from formData
      }

      // Prepare data to send, including the image if applicable
      const dataToSend = {
        ...formData,
        image: uploadedImageUrl || formData.image, // Either new uploaded URL or existing image
      };

      // Add or update menu item
      if (editItemId) {
        await axios.put(`https://bkbordeau.debuxglobal.com/api/menus/${editItemId}`, dataToSend, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        setEditItemId(null);
      } else {
        await axios.post("https://bkbordeau.debuxglobal.com/api/menus", dataToSend, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
      }

      // Reset form and close modal
      setFormData({
        name: "",
        description: "",
        price: "",
        category_id: "",
        branch_id: "",
        image: "",
      });
      setImageFile(null);
      fetchMenuItems();
      setIsModalVisible(false);
      message.success("Menu item saved successfully!");
    } catch (error) {
      console.error("Error saving menu item:", error);
      message.error("Failed to save menu item.");
    }
  };

  const handleEdit = (menuItem) => {
    setEditItemId(menuItem.id);
    setFormData({
      name: menuItem.name,
      description: menuItem.description,
      price: menuItem.price,
      category_id: menuItem.category_id,
      branch_id: menuItem.branch_id,
      image: menuItem.image || "",
    });
    setIsModalVisible(true); // Show modal on edit
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditItemId(null);
    setImageFile(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`https://bkbordeau.debuxglobal.com/api/menus/${editItemId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      fetchMenuItems();
      setIsModalVisible(false);
      setEditItemId(null);
      setImageFile(null);
      setDeleteConfirmationModalVisible(false);
      message.success("Menu item deleted successfully!");
    } catch (error) {
      console.error("Error deleting menu item:", error);
      message.error("Failed to delete menu item.");
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === "") {
      setDisplayedItems(menuItems); // Show all items if the search is cleared
    } else {
      setDisplayedItems(menuItems.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())));
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    if (category === "All") {
      setDisplayedItems(menuItems); // Show all items if "all" is selected
    } else {
      setDisplayedItems(menuItems.filter((item) => item.category_name === category));
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
          marginTop: "-15px",
        }}
      >
        <Text
          style={{
            fontSize: "36px",
            fontWeight: "bold",
            color: "#000000",
            fontFamily: "Nunito",
          }}
        >
          Add Menu Items
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              fontFamily: "Nunito",
              fontWeight: "700",
              width: "180px",
              height: "50px",
              marginRight: "10px",
              backgroundColor: "#F2890D",
              color: "white",
            }}
            onClick={() => {
              setEditItemId(null); // Clear any edit item
              setFormData({
                // Reset the form data for a new item
                name: "",
                description: "",
                price: "",
                category_id: "",
                branch_id: "",
                image: "",
              });
              setIsModalVisible(true); // Open the modal
            }}
          >
            <img src={Plus} width={12} height={12} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
            Add Product
          </Button>
        </div>
      </div>
      <Form layout="vertical" onFinish={handleSubmit} style={{ maxWidth: "600px", margin: "auto" }}>
        {/* Form inputs */}
        {/* ... */}
      </Form>

      <div style={{ marginTop: "15px" }}>
        <div
          className="search-bar"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "10px",
            marginBottom: "16px",
          }}
        >
          <Input
            placeholder="Search items"
            value={searchQuery}
            className="custom-search-input"
            onChange={(e) => handleSearch(e.target.value)}
            style={{
              width: "100%",
              fontFamily: "Nunito",
              fontWeight: "600",
              borderRadius: "10px",
              padding: "10px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
            height: "70px",
            backgroundColor: "#FFFFFF",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <div style={{ flex: 1, marginLeft: "8px", marginRight: "8px" }}>
            <Carousel
              // Link the ref to the carousel
              className="carousel"
              dots={false}
              slidesToShow={5} // Show 5 slides instead of 3
              infinite={false}
              arrows
              draggable={true}
              style={{ width: "1565px" }}
            >
              {categories.map((category) => {
                const isActive = selectedCategory === category;
                return (
                  <div
                    key={category} // Ensure to use category.id or a unique key
                    style={{
                      padding: "0 10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="default"
                      onClick={() => handleCategorySelect(category)} // Ensure to select category.name or category.id
                      style={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        fontFamily: "Nunito",
                        fontWeight: "700",
                        border: isActive ? "2px solid #F2890C" : "2px solid transparent",
                        backgroundColor: isActive ? "#F2890C1A" : "transparent",
                        color: isActive ? "#F2890C" : "#000000",
                        padding: "10px 20px",
                      }}
                    >
                      {category} {/* Render category.name or another property */}
                    </Button>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
        <Row gutter={[16, 16]}>
          {displayedItems.map((menuItem) => (
            <Col xs={24} sm={12} md={8} lg={6} key={menuItem.id}>
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "28vh", // Ensure consistent height
                  position: "relative", // Enable absolute positioning of inner elements
                  boxSizing: "border-box",
                }}
              >
                <div style={{ alignItems: "center", justifyContent: "center" }}>
                  <Row gutter={[16, 16]} style={{ width: "100%" }}>
                    <Col
                      span={8}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          overflow: "hidden", // Ensures the image is cropped to fit the circle
                          border: "3px solid #ddd", // Optional border for styling
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          alt={menuItem.name}
                          src={menuItem.image || Food} // Placeholder if no image
                          width={100}
                          height={100}
                          style={{ objectFit: "cover" }} // Ensures the image covers the entire circle
                        />
                      </div>
                    </Col>
                    <Col span={16} style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <Title level={5} style={{ fontFamily: "Nunito", fontWeight: "700" }}>
                          {menuItem.name}
                        </Title>
                      </div>
                    </Col>
                  </Row>
                </div>
                <p style={{ flexGrow: 1, marginTop: "2vh", marginBottom: "2vh" }}>
                  <Text style={{ fontFamily: "Nunito" }}>{menuItem.description}</Text>
                </p>
                <div
                  style={{
                    position: "absolute",
                    bottom: "3px", // 3px space from the bottom
                    left: "10px",
                    right: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Title
                    level={3}
                    style={{ fontFamily: "Nunito", marginLeft: "13px" }}
                  >{`LKR ${menuItem.price}`}</Title>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button icon={<EditOutlined />} onClick={() => handleEdit(menuItem)} />
                    <Button
                      icon={<DeleteOutlined />}
                      type="primary"
                      style={{
                        backgroundColor: "#F2890D",
                        borderColor: "#F2890D",
                      }}
                      onClick={() => {
                        setEditItemId(menuItem.id);
                        console.log(menuItem.id);
                        setDeleteConfirmationModalVisible(true); // Open the confirmation modal
                      }}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      {/* Modal for Editing Menu Item */}
      <Modal
        title={null} // Remove default title
        visible={isModalVisible}
        onCancel={handleCancel}
        width={700}
        closable={false}
        footer={[
          // Conditional rendering based on the mode
          editItemId ? (
            <Row gutter={16} justify="space-between">
              {/* Delete button aligned to the left */}
              <Col span={2}>
                <Button
                  key="back"
                  onClick={handleDelete}
                  icon={<DeleteOutlined />}
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    fontSize: "15px",
                    border: "3px solid #FBF5F5",
                    borderRadius: "10px",
                    height: "40px",
                    backgroundColor: "#FBF5F5",
                    color: "#FF0000",
                  }}
                >
                  Delete Product
                </Button>
              </Col>

              {/* Cancel and Save Changes buttons aligned to the right */}
              <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  key="back"
                  onClick={handleCancel}
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    fontSize: "15px",
                    border: "3px solid #EAEAEA",
                    borderRadius: "10px",
                    height: "40px",
                    marginRight: "10px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleSubmit}
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    fontSize: "15px",
                    border: "3px solid #F2890D",
                    borderRadius: "10px",
                    height: "40px",
                    backgroundColor: "#F2890D",
                  }}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          ) : (
            <Row gutter={16} justify="end">
              {/* Cancel and Add Product buttons aligned to the right */}
              <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  key="back"
                  onClick={handleCancel}
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    fontSize: "15px",
                    border: "3px solid #EAEAEA",
                    borderRadius: "10px",
                    height: "40px",
                    marginRight: "10px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleSubmit}
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    fontSize: "15px",
                    border: "3px solid #F2890D",
                    borderRadius: "10px",
                    height: "40px",
                    backgroundColor: "#F2890D",
                  }}
                >
                  Add Product
                </Button>
              </Col>
            </Row>
          ),
        ]}
      >
        {/* Wrapper div with 3px padding for all content */}
        <div
          style={{
            padding: "-20px -20px -20px",
            marginTop: "-15px",
            marginLeft: "-15px",
            marginRight: "-15px",
          }}
        >
          {/* Modal content with background and floating image */}
          <div
            style={{
              backgroundColor: "#f5f5f5", // Light grey background
              padding: "30px 40px 80px", // Adjusted padding
              position: "relative", // Required for positioning the image
              borderRadius: "10px 10px 0 0", // Rounded top corners
            }}
          >
            {/* Floating image */}
            <div
              style={{
                position: "absolute",
                bottom: "-30px", // Move image halfway outside the background
                left: "20px", // Keep it aligned to the left
                width: "100px",
                height: "100px",
                borderRadius: "50%", // Circular image
                overflow: "hidden",
                boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)", // Optional shadow effect
              }}
            >
              <Image
                alt={formData.name}
                src={formData.image || Food} // Placeholder if no image
                width={100}
                height={100}
                style={{ objectFit: "cover" }} // Ensure image fills the circle
              />
            </div>
          </div>

          {/* Title of the product */}
          <Title
            level={4}
            style={{
              marginTop: "20px",
              paddingTop: "20px",
              fontFamily: "Nunito",
              fontWeight: "700",
              paddingLeft: "20px",
            }}
          >
            {formData.name || "Product Name"}
          </Title>

          {/* Form fields for editing */}
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <Form style={{ paddingTop: "20px" }}>
              {" "}
              {/* Adjust padding top for spacing */}
              <Row gutter={[16, 16]}>
                <Col span={4}>
                  <Text
                    style={{
                      fontFamily: "Nunito",
                      position: "relative",
                      top: "10px",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    Item Name
                  </Text>
                </Col>
                <Col span={20}>
                  <Form.Item>
                    <Input
                      value={formData.name}
                      onChange={(e) => handleChange(e.target.value, "name")}
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        fontSize: "15px",
                        border: "3px solid #EAEAEA",
                        borderRadius: "10px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Divider style={{ margin: "0", padding: "0" }} />
                <Col span={4}>
                  <Text
                    style={{
                      fontFamily: "Nunito",
                      position: "relative",
                      top: "10px",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    Description
                  </Text>
                </Col>
                <Col span={20}>
                  <Form.Item>
                    <Input.TextArea
                      value={formData.description}
                      onChange={(e) => handleChange(e.target.value, "description")}
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        fontSize: "15px",
                        border: "3px solid #EAEAEA",
                        borderRadius: "10px",
                      }}
                      maxLength={100}
                    />
                  </Form.Item>
                </Col>
                <Divider style={{ margin: "0", padding: "0" }} />
                <Col span={4}>
                  <Text
                    style={{
                      fontFamily: "Nunito",
                      position: "relative",
                      top: "10px",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    Regular Price
                  </Text>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <Input
                      value={formData.price}
                      onChange={(e) => handleChange(e.target.value, "price")}
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        fontSize: "15px",
                        border: "3px solid #EAEAEA",
                        borderRadius: "10px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Text
                    style={{
                      fontFamily: "Nunito",
                      position: "relative",
                      top: "10px",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    Dis Price
                  </Text>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <Input
                      value={formData.discountPrice}
                      onChange={(e) => handleChange(e.target.value, "discountPrice")}
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        fontSize: "15px",
                        border: "3px solid #EAEAEA",
                        borderRadius: "10px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Divider style={{ margin: "0", padding: "0" }} />
                <Col span={3}>
                  <Text
                    style={{
                      fontFamily: "Nunito",
                      position: "relative",
                      top: "10px",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    Category
                  </Text>
                </Col>
                <Col span={9}>
                  <Form.Item>
                    <Select
                      value={formData.category_id}
                      onChange={(value) => handleChange(value, "category_id")}
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        fontSize: "15px",
                        border: "3px solid #EAEAEA",
                        borderRadius: "10px",
                        height: "40px",
                      }}
                    >
                      {optcategories.map((category) => (
                        <Option
                          key={category.id}
                          value={category.id}
                          style={{
                            fontFamily: "Nunito",
                            fontWeight: "700",
                            fontSize: "15px",
                          }}
                        >
                          {category.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Text
                    strong
                    style={{
                      fontFamily: "Nunito",
                      position: "relative",
                      top: "10px",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    Branch
                  </Text>
                </Col>
                <Col span={9}>
                  <Form.Item>
                    <Select
                      value={formData.branch_id}
                      onChange={(value) => handleChange(value, "branch_id")}
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        fontSize: "15px",
                        border: "3px solid #EAEAEA",
                        borderRadius: "10px",
                        height: "40px",
                      }}
                    >
                      {branches.map((branch) => (
                        <Option
                          key={branch.id}
                          value={branch.id}
                          style={{
                            fontFamily: "Nunito",
                            fontWeight: "700",
                            fontSize: "15px",
                          }}
                        >
                          {branch.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Divider style={{ margin: "0", padding: "0" }} />
                <Col span={6}>
                  <Text
                    strong
                    style={{
                      fontFamily: "Nunito",
                      position: "relative",
                      top: "10px",
                      fontWeight: "700",
                      fontSize: "15px",
                    }}
                  >
                    Upload Image
                  </Text>
                </Col>
                <Col span={18}>
                  <Form.Item>
                    <Row gutter={[16, 16]} align="middle">
                      <Col span={8}>
                        {/* Circular display for the current or new image */}
                        <div
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                            border: "3px solid #ddd",
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Image
                            alt={formData.name}
                            src={imageFile ? URL.createObjectURL(imageFile) : formData.image || Food}
                            width={80}
                            height={80}
                            style={{ objectFit: "cover" }}
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        {/* Button to upload a new image */}
                        <Upload
                          listType="picture"
                          beforeUpload={() => false} // Prevent automatic upload
                          onChange={handleFileChange}
                          accept="image/*"
                          maxCount={1}
                          showUploadList={false} // Hide the default upload list
                          style={{ fontFamily: "Nunito", fontWeight: "500", fontSize: "15px" }}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            style={{
                              fontFamily: "Nunito",
                              fontWeight: "500",
                              fontSize: "15px",
                              border: "3px solid #EAEAEA",
                              borderRadius: "10px",
                            }}
                          >
                            Upload
                          </Button>
                        </Upload>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal
        visible={deleteConfirmationModalVisible}
        onCancel={() => setDeleteConfirmationModalVisible(false)} // Close modal if 'No' is clicked
        footer={null} // Custom footer, so we add buttons manually
      >
        <div style={{ textAlign: "center" }}>
          <Text
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "Nunito",
            }}
          >
            Are you sure you want to delete this product?
          </Text>
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {/* No Button */}
          <Button
            onClick={() => setDeleteConfirmationModalVisible(false)}
            style={{
              fontFamily: "Nunito",
              fontWeight: "500",
              fontSize: "15px",
              backgroundColor: "#F2890D",
              color: "white",
              borderRadius: "10px",
              height: "40px",
              border: "none",
            }}
          >
            No
          </Button>
          {/* Yes Button */}
          <Button
            onClick={handleDelete}
            style={{
              fontFamily: "Nunito",
              fontWeight: "500",
              fontSize: "15px",
              backgroundColor: "black",
              color: "white",
              borderRadius: "10px",
              height: "40px",
              border: "none",
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default MenuItemForm;
